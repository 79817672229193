import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { DataLayerEvents } from '../../../interfaces/tracking';
import { pushAvailability } from '../../../utils/dataLayer/availability';
import { getExpansionAvailabilityStatus } from '../../../utils/dataLayer/getExpansionStatus';
import { checkMandantType, getEnv, isNC } from '@ncs-frontend-monorepo/utils';
import {
  AvailabilityProvider,
  AvailabilityTypes,
} from '../../AvailabilityCheck/Context';
import {
  AvailabilityForm,
  FormFieldValues,
} from '../../AvailabilityCheck/Views/Form';
import {
  ExpansionStatus,
  Fallback,
  hasTechnologies,
  useAvailability,
  Technology,
} from '@ncs-frontend-monorepo/availability';
import { useReset } from '@ncs-frontend-monorepo/order';

const AreaPlanned = dynamic(() => import('./AreaPlanned'));
const Error = dynamic(() => import('./Error'));
const HomesConnected = dynamic(() => import('./HomesConnected'));
const HomesPassed = dynamic(() => import('./HomesPassed'));
const HomesPrepared = dynamic(() => import('./HomesPrepared'));
const NotPlanned = dynamic(() => import('./NotPlanned'));
const OutOfArea = dynamic(() => import('./OutOfArea'));
const PreMarketing = dynamic(() => import('./PreMarketing'));
const UnderConstruction = dynamic(() => import('./UnderConstruction'));
const Presale = dynamic(() => import('./Presale'));
const Cable = dynamic(() => import('./Cable'));
const FTTB = dynamic(() => import('./FTTB'));
const FallbackModal = dynamic(() => import('./Fallback'));

export interface ContentProviderProps {
  e2e: string;
  availabilityType?: AvailabilityTypes;
  requestedDownload?: number;
}
const ContentProvider: React.FC<ContentProviderProps> = ({
  e2e,
  availabilityType = AvailabilityTypes.Expansion,
  requestedDownload,
}) => {
  const [view, setView] = useState<ExpansionStatus>(ExpansionStatus.UNKNOWN);
  const { address, availability, handleAvailabilityCheck } = useAvailability();
  const { resetAll } = useReset();
  const router = useRouter();
  const {
    eventOpen,
    eventResult,
    eventClose,
    eventCheck,
    eventChangeAddress,
    getAvailabilityStatus,
  } = pushAvailability();

  const mandantType = checkMandantType(getEnv().SITE, address?.mandant);

  const resetAddress = () => {
    eventChangeAddress({
      event: DataLayerEvents.ChangeAddress,
      status: getAvailabilityStatus(availability.promotions, requestedDownload),
      checkSource: availabilityType,
      zipCode: address.zipCode,
      maxDownload: availability.maxDownload,
    });
    setView(ExpansionStatus.UNKNOWN);
    resetAll();
  };

  e2e = `${view.toLowerCase()}-${e2e}`;

  const availabilityChecked = !!(
    availability.address &&
    (availability.maxDownload || availability.planned)
  );

  useEffect(() => {
    eventOpen({
      event: DataLayerEvents.Open,
      checkStatus: availabilityChecked ? 'bereits-geprueft' : 'nicht-geprueft',
      checkSource: availabilityType,
    });

    return () => {
      eventClose({
        event: DataLayerEvents.Close,
        checkSource: availabilityType,
      });
    };
  }, []);

  const handleCheck = useCallback(async (params: FormFieldValues) => {
    eventCheck({
      event: DataLayerEvents.Check,
      checkSource: AvailabilityTypes.Expansion,
      zipCode: params.zipCode,
    });
    await handleAvailabilityCheck({
      address: params,
      loggingInfo: {
        manualChecked: true,
      },
      isNotConnectedCheck: true,
    });
  }, []);

  useEffect(() => {
    if (availability.isChecked) {
      const {
        address,
        objectInformation,
        promotions: { availablePromotions, presalePromotion },
      } = availability;

      //redirect to suitable mandant
      if (!!address?.mandant && address?.mandant !== (isNC() ? 'NC' : 'NA')) {
        if (address?.mandant === 'NA') {
          router.push(`https://www.netaachen.de/${router.asPath}`);
        } else {
          router.push(`https://www.netcologne.de/${router.asPath}`);
        }
        return;
      }

      //show error on
      if (!availability.objectInformation) {
        setView(ExpansionStatus.ERROR);
        return;
      }

      const isFallback =
        availability.promotions.fallback === Fallback.TECHNOLOGY;

      if (isFallback) {
        setView(ExpansionStatus.FALLBACK);
        return;
      }

      if (presalePromotion) {
        setView(ExpansionStatus.PRE_SALE);
        return;
      }

      // CABLE-technology
      if (
        hasTechnologies({
          technologies: [Technology.CABLE],
          availablePromotions,
          presalePromotion,
        })
      ) {
        setView(ExpansionStatus.CABLE);
        return;
      }

      // special handling for GFAST and FTTH
      if (
        hasTechnologies({
          technologies: [
            Technology.GFAST,
            Technology.FTTH,
            Technology.FTTH_BSA_L2,
            Technology.FTTH_BSA_L2_DG,
          ],
          availablePromotions,
          presalePromotion,
        })
      ) {
        setView(ExpansionStatus.HOMES_CONNECTED);
        return;
      }

      // special handling for FTTB
      if (
        hasTechnologies({
          technologies: [Technology.VDSL2_FTTB],
          availablePromotions,
        })
      ) {
        setView(ExpansionStatus.FTTB);
        return;
      }

      setView(objectInformation.status);
    }
  }, [availability]);

  useEffect(() => {
    if (view !== ExpansionStatus.UNKNOWN) {
      eventResult({
        event:
          mandantType !== 'match'
            ? DataLayerEvents.ResultMandantMismatch
            : DataLayerEvents.Result,
        status: getExpansionAvailabilityStatus(view),
        expansionStatus: view || null,
        checkSource: AvailabilityTypes.Expansion,
        checkStatus: availabilityChecked
          ? 'bereits-geprueft'
          : 'nicht-geprueft',
        zipCode: address?.zipCode,
        maxDownload: availability.maxDownload,
        presaleAvailability: !!availability.planned,
        ...(availability.planned && {
          plannedAvailabilityDate:
            availability.planned.plannedAvailabilityDateDescription,
        }),
      });
    }
  }, [view, mandantType]);

  return (
    <AvailabilityProvider availabilityType={AvailabilityTypes.Expansion}>
      {view === ExpansionStatus.UNKNOWN && (
        <AvailabilityForm
          headline="Ausbaustatus prüfen"
          subline="Prüfe jetzt, ob sich deine Adresse in unserem Glasfaser-Ausbaugebiet befindet."
          onSubmit={handleCheck}
        />
      )}
      {view === ExpansionStatus.NOT_PLANNED && (
        <NotPlanned e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {view === ExpansionStatus.AREA_PLANNED && (
        <AreaPlanned e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {view === ExpansionStatus.PRE_MARKETING && (
        <PreMarketing e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {view === ExpansionStatus.UNDER_CONSTRUCTION && (
        <UnderConstruction e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {[
        ExpansionStatus.HOMES_PASSED,
        ExpansionStatus.HOMES_PASSED_PLUS,
      ].includes(view) && (
        <HomesPassed e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {[ExpansionStatus.HOMES_PREPARED, ExpansionStatus.HOMES_READY].includes(
        view,
      ) && <HomesPrepared e2e={e2e} onChangeAddressClick={resetAddress} />}
      {[ExpansionStatus.NETWORK_NC, ExpansionStatus.HOMES_CONNECTED].includes(
        view,
      ) && <HomesConnected e2e={e2e} onChangeAddressClick={resetAddress} />}
      {view === ExpansionStatus.OUT_OF_AREA && (
        <OutOfArea e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {view === ExpansionStatus.PRE_SALE && (
        <Presale e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {view === ExpansionStatus.CABLE && (
        <Cable e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {view === ExpansionStatus.FTTB && (
        <FTTB e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {view === ExpansionStatus.ERROR && (
        <Error e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {view === ExpansionStatus.FALLBACK && <FallbackModal e2e={e2e} />}
    </AvailabilityProvider>
  );
};

export { ContentProvider };
